.data-card {
  /* max-width: 750px; */
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    max-width: 100%;
}

.heading-div {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  align-items: center;
}

.content-div {
  display: flex;
  justify-content: center;
  /* justify-content: flex-start; */
  gap: 20px;
  flex-wrap: wrap;
}

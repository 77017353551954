.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-app {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* height: 85vh; */
  /* margin: 100px 30px 40px 40px; */
  border-radius: 20px;
}
@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-out-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
.component {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: slide-in-right 1s ease forwards;
  /* animation: slide-out-left 1s ease forwards; */

}

.slide-in {
  animation: slide-in-right 1s ease forwards;
}
.slide-out {
  animation: slide-out-left 1s ease forwards;
}

.left-side {
  /* background-color: red; */
  /* margin-left: 40px; */
  /* width: 50vw; */
  padding: 0px 20px;
}

.right-side {
  /* background-color: pink; */
  /* width: 50vw; */
  /* margin-left:40px; */
  /* margin-right: 40px; */
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0px 20px;
}

@media (min-width: 900px) {
  .component {
    flex-direction: row;
  }

  .left-side {
    margin-left: 0px;
  }
  .right-side {
    margin-left: 0px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.rating{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.selection-buttons{
    display: flex;
    gap:15px;
    flex-wrap: wrap;
}

@media(min-width: 900px){
    .rating{
        gap: 30px;
    }
    .selection-buttons{
        display: flex;
        gap:30px
    }
  }
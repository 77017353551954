.pushable {
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: filter 600ms;
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 105%;
  height: 105%;
  border-radius: 12px;
  /* background: hsl(0deg 0% 0% / 0.25); */
  transform: translateY(2px);
  filter: blur(4px);
}
.edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 105%;
  height: 105%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}
.front {
  display: block;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  color: white;
  /* background: hsl(345deg 100% 47%); */
  transform: translateY(-4px);
}

/* .pushable:hover .front {
  transform: translateY(-6px) translateX(-6px);
  transition: filter 250ms;
  filter: brightness(110%);
}
.pushable:hover .shadow {
  transform: translateY(4px); 
  
} */
@media (min-width: 900px) {
  .pushable:hover .front {
    transform: translateY(-6px) translateX(-6px);
    transition: filter 250ms;
    filter: brightness(110%);
  }
  .pushable:hover .shadow {
    transform: translateY(4px);
  }
}
.pushable:active .front {
  transform: translateY(-2px) translateX(2px);
}
.pushable:active .shadow {
  transform: translateY(1px);
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}
